<template>
  <v-layout row wrap align-space-around justify-center fill-height>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <titleCard title="Encuestas" :subtitle="`${buyer.encuestas.length} encuestas contestadas`"></titleCard>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="polls"
          class="elevation-0 order-table"
          color="secondary"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          no-data-text="No hay encuestas ingresados"
          :loading="loading"
        >
          <v-progress-linear v-if="loading" slot="progress" color="primary" indeterminate> </v-progress-linear>
          <template slot="headers" slot-scope="props">
            <tr>
              <th v-for="header in props.headers" :key="header.text" :class="`text-xs-${header.align}`">
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr :active="props.selected" @click="openPoll(props)">
              <td class="text-xs-center">
                <span v-if="props.item.showPhoto">
                  <img
                    style="width:100px;"
                    class="my-2"
                    :src="props.item.order.photo_shipping"
                    :alt="props.item.order.name_shipping"
                  />
                </span>
                <v-icon v-else> not_interested </v-icon>
              </td>
              <td class="text-xs-right">{{ props.item.order.id }}</td>
              <td class="text-xs-left">{{ props.item.user.name }}</td>
              <td class="text-xs-left">{{ getInput1(props.item.input1) }}</td>
              <td class="text-xs-left">
                <v-icon v-if="props.item.input4 === 1" color="success">
                  thumb_up
                </v-icon>
                <v-icon v-else> thumb_down </v-icon>
              </td>
              <td class="text-xs-left" v-html="props.item.input5"></td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
    <v-dialog v-model="dialogPoll" scrollable max-width="700px">
      <v-card v-if="_.size(poll) > 0">
        <titleDialog
          :title="`Encuesta Nº${poll.order.id}`"
          :close-action="
            () => {
              dialogPoll = false
            }
          "
          close
        >
          <v-spacer></v-spacer>

          <v-btn
            v-if="poll.showPhoto"
            target="_blank"
            :href="`http://m.rosalinda.cl/pageTestimonio.php?id=${poll.id}`"
            icon
          >
            <v-icon> link </v-icon>
          </v-btn>
        </titleDialog>
        <v-card-text class="pa-2">
          <v-container grid-list-lg>
            <v-layout wrap>
              <v-subheader>Cliente</v-subheader>
              <v-divider></v-divider>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field :value="poll.user.name" label="Nombre" readonly></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field :value="poll.user.email" label="Email" readonly></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field :value="poll.user.phone" label="Teléfono" readonly></v-text-field>
                </v-flex>
              </v-layout>
              <v-flex xs12 sm6 md12>
                <v-subheader>Pedido</v-subheader>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <img :src="poll.order.photo_shipping" :alt="poll.order.name_shipping" style="width:100%;" />
                  <v-btn
                    v-if="poll.showPhoto"
                    :href="poll.order.photo_shipping"
                    :download="poll.order.photo_shipping"
                    target="_blank"
                    color="primary"
                    block
                    depressed
                    @click.native="dialogPoll = false"
                  >
                    <v-icon left> cloud_download </v-icon> Descargar foto
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 md8 class="pl-4">
                  <v-text-field :value="poll.order.id" label="Pedido" readonly></v-text-field>
                  <v-text-field :value="poll.order.name_shipping" label="Destinatario(a)" readonly></v-text-field>
                </v-flex>
                <v-flex v-if="!poll.showPhoto" xs12 sm6 md12>
                  <v-alert :value="true" type="error">
                    No esta permitido usar la foto de entrega
                  </v-alert>
                </v-flex>
              </v-layout>
              <v-flex xs12 sm6 md12>
                <v-subheader>Encuesta</v-subheader>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    :value="getInput1(poll.input1)"
                    label="Como llegó a nuestro sitio web"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    :value="getInput2(poll.input2)"
                    label="Sitio web"
                    :append-icon="poll.input2 < 3 ? 'error' : ''"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field
                    :value="getInput2(poll.input3)"
                    label="Atención telefónica, chat y correo"
                    :append-icon="poll.input3 < 3 ? 'error' : ''"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :value="poll.rateDelivery"
                    label="Calificacion repartidor"
                    append-icon="star"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :value="poll.input4 === 1 ? 'Si' : 'No'"
                    label="Recomendaría nuestro servicio"
                    :append-icon="poll.input4 === 1 ? 'thumb_up' : 'thumb_down'"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :value="poll.showPhoto ? 'Si' : 'No'"
                    label="Usar la foto de entrega"
                    :append-icon="poll.showPhoto ? 'thumb_up' : 'thumb_down'"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    :value="poll.rateDelivery"
                    label="Calidad de la foto"
                    append-icon="star"
                    readonly
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="poll.input5 != ''" xs12 sm6 md12>
                  <v-textarea
                    label="Comentario bueno o malo de nuestro servicio"
                    :value="poll.input5"
                    readonly
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { CLIENT } from '../../config'
import titleCard from '../useful/titleCard.vue'
import titleDialog from '../useful/titleDialog.vue'

export default {
  name: 'BuyerPolls',
  components: { titleCard, titleDialog },
  props: ['buyer'],
  data() {
    return {
      polls: [],
      poll: {},
      dialogPoll: false,
      loading: false,
      headers: [
        { text: 'Foto de entrega', align: 'right' },
        { text: 'Pedido', align: 'right' },
        { text: 'Cliente', align: 'left' },
        { text: 'Como llegó?', align: 'left' },
        { text: 'Recomienda', align: 'left' },
        { text: 'Comentario', align: 'left' }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      filter: {
        dateStart: this.$moment()
          .subtract(1, 'year')
          .format('YYYY-MM-DD'),
        dateEnd: this.$moment().format('YYYY-MM-DD'),
        show: false,
        web: false,
        iduser: 0
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getPolls()
  },
  methods: {
    async getPolls() {
      this.polls = []
      this.selected = []
      this.loading = true
      this.filter.iduser = this.buyer.id
      try {
        const res = await this.$http.get(
          `${CLIENT}/${this.buyer.id}/polls?${this.$qs.stringify({
            filters: this.filter
          })}`
        )
        console.log('getPolls =>', res)
        this.polls = res.data
        this._.forEach(this.polls, item => {
          this.selected.push(item)
        })
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    editItem(item) {
      console.log('Editar', item)
    },
    deleteItem(item) {
      console.log('Eliminar', item)
    },
    getInput1(id) {
      let message = ''
      switch (id) {
        case 1:
          message = 'Se lo recomendaron'
          break
        case 2:
          message = 'Vio alguno de nuestros productos o vehículos'
          break
        case 3:
          message = 'Es cliente'
          break
        case 4:
          message = 'Link en otro sitio web'
          break
        case 5:
          message = 'Buscando en Google'
          break
        case 6:
          message = 'Escuchó anuncio en la radio'
          break
        case 7:
          message = 'Por medio de un SMS'
          break
        case 8:
          message = 'Por Facebook'
          break
        case 9:
          message = 'Por Instagram'
          break
        default:
          message = ''
          break
      }
      return message
    },
    getInput2(id) {
      let mensaje = ''
      switch (id) {
        case 1:
          mensaje = 'Insatisfecho'
          break
        case 2:
          mensaje = 'Moderadamente'
          break
        case 3:
          mensaje = 'Satisfecho'
          break
        case 4:
          mensaje = 'Muy satisfecho'
          break
        default:
          mensaje = ''
          break
      }
      return mensaje
    },
    openPoll(prop) {
      this.poll = prop.item
      this.dialogPoll = true
    }
  }
}
</script>

<style></style>
